
body {
  background-color: #ffffff;
  min-height: 100vh;
  font: normal 16px sans-serif;
  padding: 40px 0;
}


.st-title {
  text-align:center;
  font-family: calluna-sans;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 22px;
}


.jumbotron {
  text-align: center;
  font-family: proxima-nova;
}

.st-footer {
  color: #757575;
  font-family: clby;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: none;
}

.container.gallery-container {
  background-color: #fff;
  color: #35373a;
  min-height: 100vh;
  padding: 10px 10px;
  position: relative;
}

a.nav-link {
  color: #7a7a7a;
  font-family: proxima-nova;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6em;
  font-size: 14.5px;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.fab.fa-instagram {
  position: relative;
  right: 0;
}

.gallery-container h1 {
  text-align: center;
  margin-top: 50px;
  font-family: 'Droid Sans', sans-serif;
  font-weight: bold;
}

.gallery-container p.page-description {
  text-align: center;
  margin: 5px auto;
  font-size: 18px;
  color: #999;
}

/* Override bootstrap column paddings */
.row > div {
  display: block;
  width:  100%; 
}

.waterfall > [class*='col-'] {
  padding-right:1px;
  padding-left:1px;
  padding-top: 4px;
}


.lightbox {
  padding-top: 5px;
}

.img-fluid {
  display: block;
  width: auto;
  max-height: 100%;
  border-radius: 0;
  position: relative;
}

.lightbox:hover{
  opacity: 0.5;
  color: orange;
  cursor: pointer;
}

@media(max-width: 1080px) {
  body {
      padding: 0;
  }
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}


.arrow {
  border: solid rgb(133, 120, 120);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}